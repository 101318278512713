.slideshow-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.slideshow img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    animation: fade 15s infinite;
}

.slideshow img:nth-child(1) {
    animation-delay: -3s;
}

.slideshow img:nth-child(2) {
    animation-delay: 2s;
}

.slideshow img:nth-child(3) {
    animation-delay: 7s;
}

@keyframes fade {
    0% { opacity: 0; }
    20% { opacity: 0.55; }
    33% { opacity: 0.55; }
    66% { opacity: 0; }
    100% { opacity: 0; }
}