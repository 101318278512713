.fade-in {
    opacity: 0;
    animation: fadeIn 0.4s forwards;
}
  
.fade-out {
    opacity: 1;
    animation: fadeOut 0.4s forwards;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #f3f0e2;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}