/* RestaurantBooking.css */

.booking-card {
    max-width: 600px;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.booking-header {
  margin-bottom: 20px;
}

.booking-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.booking-description {
  color: #666;
  font-size: 14px;
}

.progress-bar {
  height: 10px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  background-color: #133C31;
  transition: width 0.3s ease-in-out;
}

.step-indicator {
  font-size: 14px;
  color: #666;
}

.booking-content {
  margin-bottom: 20px;
}

.booking-type {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.booking-option {
  flex: 1;
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
}

.booking-option:hover {
  border-color: #133C31;
}

.booking-option.selected {
  border-color: #133C31;
  background-color: #e8f5e9;
}

.booking-option h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.booking-option p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-control:focus {
  outline: none;
  border-color: #133C31;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.error {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 5px;
}

.booking-summary {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.booking-summary h3 {
  margin-top: 0;
  color: #333;
}

.booking-summary p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.booking-footer {
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: bold;
}

.btn-primary {
  background-color: #133C31;
  color: white;
}

.btn-secondary {
  background-color: #f0f0f0;
  color: #333;
}

.btn:hover {
  opacity: 0.8;
}

/* Responsive styles */
@media (max-width: 600px) {
  .booking-card {
    padding: 15px;
  }

  .booking-type {
    flex-direction: column;
    gap: 10px;
  }

  .booking-option {
    width: 100%;
  }

  .booking-footer {
    flex-direction: column;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }
}